/** @format */

// import { Link } from 'react-router-dom'
import { Nav, Tab, Button } from 'react-bootstrap';
import Card from '../../../src/components/Card';
import * as React from 'react';
//flatpicker
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import { useEffect } from 'preact/hooks';
const Calenders = (props) => {
  const [toggleState, setToggleState] = React.useState(true);
  const [mindDate, setMinDate] = React.useState('');
  const [mindDateStart, setMinDateStart] = React.useState('');
  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');
  //   React.useEffect(() => {
  //     setMinDateStart(props.minDate);
  //   }, [props.minDate]);

  const handleChange = (event, value) => {
    if (value !== props.startDate) {
      props.handle_start_date(value);
    } else {
      props.handle_start_date('');
    }
  };

  const handleEvent = (event, value) => {
    if (value !== props.endDate) {
      props.handle_end_date(value);
    } else {
      props.handle_end_date('');
    }
  };
  return (
    <>
      <article id='calendar'>
        <Card>
          <Tab.Container defaultActiveKey='first'>
            <Tab.Content className=''>
              <Tab.Pane eventKey='first' onChange={handleEvent}>
                <div className=''>
                  <div
                    className=' vanila-daterangepicker d-flex flex-row '
                    style={{ justifyContent: 'space-around', display: 'flex' }}>
                    <Flatpickr
                      value={props.startDate}
                      onChange={handleChange}
                      className='form-control range_flatpicker'
                      placeholder='Select Date...  '
                      style={{ width: '90%', margin: '10px' }}
                    />

                    <Flatpickr
                      //   options={{ minDate: mindDate }}
                      value={props.endDate}
                      onChange={handleEvent}
                      className='form-control range_flatpicker'
                      placeholder='Select Date...'
                      style={{ width: '90%', margin: '10px' }}
                    />
                  </div>
                </div>
                <div className='bd-example'>
                  <div className='input-group'>
                    <div className=''></div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane
                eventKey='second'
                className={
                  toggleState === false ? 'content  active-content' : 'content'
                }
                id='content-Calendar-code'
                role='tabpanel'
                aria-labelledby='typo-output'>
                <div className='section-block'>
                  <pre>
                    <code className='language-markup'>
                      <div className='bd-example'>
                        <div className='form-group vanila-daterangepicker d-flex flex-row'>
                          <input
                            type='text'
                            name='start'
                            className='form-control'
                            placeholder='From Date'
                          />
                          <span className='flex-grow-0'>
                            <span className='btn'>To</span>
                          </span>
                          <input
                            type='text'
                            name='end'
                            className='form-control'
                            placeholder='From Date'
                          />
                        </div>
                      </div>
                      <div className='bd-example'>
                        <div className='input-group'>
                          <div className='vanila-datepicker'></div>
                        </div>
                      </div>
                    </code>
                  </pre>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Card>
      </article>
    </>
  );
};
export default Calenders;
