import React from 'react'
import Chip from '@mui/material/Chip';

const CustomChip = (props) => {

    const handleDelete = () => {
        var newQuery = JSON.parse(JSON.stringify(props.query))

        if (props.element.db_field == "source") {
            var index = props.query.source.$in.indexOf(props.element.label)
            if (index > -1) newQuery.source.$in.splice(index, 1)
            if (newQuery.source.$in.length == 0) delete newQuery.source
        }

        if (props.element.db_field == "start_at") {
            delete newQuery.start_at
            const year = (new Date).getFullYear();
            const currentYearStart = new Date(`01 January ${year} 00:00 UTC`)
            const currentYearEnd = new Date(`31 December ${year} 23:59 UTC`)
            newQuery.start_at = { $gte: currentYearStart.toISOString(), $lte: currentYearEnd.toISOString() }
        }

        if (props.element.db_field == "rental_id") {
            var index = props.query.rental_id.$in.indexOf(props.element.rental_id)
            if (index > -1) newQuery.rental_id.$in.splice(index, 1)
            if (newQuery.rental_id.$in.length == 0) delete newQuery.rental_id
        }

        if (props.element.db_field == "bedrooms_count") {
            delete newQuery.bedrooms_count
        }

        if (props.element.db_field == "sleeps") {
            delete newQuery.sleeps
        }

        if (props.element.db_field == "adresse_finale") {
            var index = props.query.adresse_finale.$in.indexOf(props.element.label)
            if (index > -1) newQuery.adresse_finale.$in.splice(index, 1)
            if (newQuery.adresse_finale.$in.length == 0) delete newQuery.adresse_finale
        }
        props.setQuery(newQuery)
    };

    return (
        <>
            <Chip label={props.label} onDelete={handleDelete} style={{ margin: '5px', padding: '5px' }} />
        </>
    )
}
export default CustomChip;