/** @format */

import React from 'react';

//sidebar
import Sidebar from '../../components/partials/dashboard/SidebarStyle/sidebar';

import DefaultRouter from '../../router/default-router';

import Loader from '../../components/Loader';

import { connect } from 'react-redux';

const Default = (props) => {
  return (
    <>
      <Loader />
      <Sidebar />
      <main className='main-content'>
        <div className='position-relative'></div>
        <div className='py-0 conatiner-fluid content-inner mt-n5'>
          <DefaultRouter />
        </div>
      </main>
    </>
  );
};

export default connect()(Default);
