/** @format */

export const CLIENT_ID = process.env.REACT_APP_ENVIRONNEMENT.includes(
  'development'
)
  ? 'beb1c5e39cf2e56547c9f3f14277a6596313b0931553427a871f25ee95626409'
  : 'b29c8d9317ddcf8588fe72b4cda8dc87d7cc9086369df51e3737ba5c2e05bcd0';

export const BASE_URI_NODE = process.env.REACT_APP_ENVIRONNEMENT.includes(
  'development'
)
  ? 'http://localhost:4000/server/api/v1'
  : 'https://managers.homesuitehome.fr/server/api/v1';

// export const BASE_URI_NODE = 'http://localhost:4000/server/api/v1'

export const CLIENT_SECRET = process.env.REACT_APP_ENVIRONNEMENT.includes(
  'development'
)
  ? '5ac026a3ba071da98608c073ec7e9821f7aef8637aff7c6b2ca36703f34a8fd9'
  : '8d979548361d76daa298c9175a79794d88c1d9ba7a32ec2d8185bde0bbc0aa94';

export const REDIRECT_URI = process.env.REACT_APP_ENVIRONNEMENT.includes(
  'development'
)
  ? 'https://localhost:3000'
  : 'https://managers.homesuitehome.fr';

export const BOOKING_URI = 'https://www.bookingsync.com/api/v3/';

export const Authorisation_URI = `https://www.bookingsync.com/oauth/authorize?client_id=${CLIENT_ID}
&redirect_uri=${REDIRECT_URI}&response_type=code&scope=bookings_read%20rentals_read%20payments_read%20`;
