/** @format */
import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
//circular
import Circularprogressbar from '../../components/circularprogressbar.js';
import FilterListIcon from '@mui/icons-material/FilterList';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
// AOS
import AOS from 'aos';
import '../../../node_modules/aos/dist/aos';
import '../../../node_modules/aos/dist/aos.css';
//apexcharts
import Chart from 'react-apexcharts';
//swiper
import Calenders from '../../views/uikit/calender';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import 'swiper/components/navigation/navigation.scss';
import ApexCharts from '../dashboard/charts/apexchart';
//Count-up
import CountUp from 'react-countup';
//api
import '../dashboard/styles/checkboxes.css';
import '../dashboard/styles/card_stats.css';
import {
  Get_Bookings_Stats,
  Get_Bookings_Sources_Stats,
  Get_Main_Global_Stats,
  Get_All_Adresses,
} from '../../api/action_booking.js';
// MUI
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Typography } from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ClipLoader from 'react-spinners/ClipLoader';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

import { Get_all_Filter_Rentals } from '../../api/action_rentals.js';
import { Get_Bedroom_Count, Get_Sleeping_Bed_Count, Get_All_Years, Get_Occupation_Rate } from '../../api/action_booking';
// store
import {
  NavbarstyleAction,
  getDirMode,
  getcustomizerMode,
  getcustomizerprimaryMode,
  getcustomizerinfoMode,
  SchemeDirAction,
  ColorCustomizerAction,
  getNavbarStyleMode,
  getSidebarActiveMode,
  SidebarActiveStyleAction,
  getDarkMode,
  ModeAction,
  SidebarColorAction,
  getSidebarColorMode,
  getSidebarTypeMode,
} from '../../store/setting/setting';
import { connect } from 'react-redux';
import CustomChip from './components/CustomChip.js';

// install Swiper modules

SwiperCore.use([Navigation]);
const mapStateToProps = (state) => {
  return {
    darkMode: getDarkMode(state),
    customizerMode: getcustomizerMode(state),
    cololrinfomode: getcustomizerinfoMode(state),
    colorprimarymode: getcustomizerprimaryMode(state),
    schemeDirMode: getDirMode(state),
    sidebarcolorMode: getSidebarColorMode(state),
    sidebarTypeMode: getSidebarTypeMode(state),
    sidebaractivestyleMode: getSidebarActiveMode(state),
    navbarstylemode: getNavbarStyleMode(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      ModeAction,
      SchemeDirAction,
      SidebarColorAction,
      SidebarActiveStyleAction,
      NavbarstyleAction,
      ColorCustomizerAction,
    },
    dispatch
  ),
});
const Index = (props) => {
  const [open, setOpen] = React.useState(false);
  const [yearStats, setyearStats] = useState([]);

  const year = (new Date).getFullYear();
  const [currentYear, setCurrentYear] = useState(year);
  const currentYearStart = new Date(`01 January ${year} 00:00 UTC`)
  const currentYearEnd = new Date(`31 December ${year} 23:59 UTC`)
  const [query, setQuery] = useState({ start_at: { $gte: currentYearStart.toISOString(), $lte: currentYearEnd.toISOString() } });
  ///Filter States
  const [isFiltred, setisFiltred] = useState(false);
  const [Sources, SetSources] = useState([
    'Airbnb',
    'Booking.com',
    'homesuitehome.com',
    'Réservation Direct',
  ]);
  const [StartDate, setStartDate] = useState('');
  const [EndDate, setEndDate] = useState('');
  const [mindDate, setMinDate] = useState('');
  const [RentalsOptions, setRentalsOption] = useState([]);
  const [Adress, setAdress] = useState([]);

  const [value, setValue] = React.useState([]);
  const [SourceStats, SetSourceStats] = useState([]);
  const [reservations, Setreservations] = useState([]);
  const [GlobalStat, SetGlobalStat] = useState([]);
  const [OldData, setOldData] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const [occupationRate, setOccupationRate] = useState(0);
  const [averagePrice, setAveragePrice] = useState(0);

  const [BedroomCount, setBedroomCount] = useState([]);
  const [BedroomCountValue, setBedroomCountValue] = useState(0);

  const [SleepingBedCount, setSleepingBedCount] = useState([]);
  const [SleepingBedCountValue, setSleepingBedCountValue] = useState(0);

  const [AdressList, setAdressList] = useState([]);
  const [AdressValue, setAdressValue] = useState([])

  const [years, setYears] = useState([]);
  const [yearFilter, setYearFilter] = useState(0);

  const [Loading, setLoading] = useState(false)

  const [queryFilterArray, setQueryFilterArray] = useState([])

  useEffect(() => {
    AOS.init({
      startEvent: 'DOMContentLoaded',
      disable: function () {
        var maxWidth = 996;
        return window.innerWidth < maxWidth;
      },
      throttleDelay: 10,
      once: true,
      duration: 700,
      offset: 10,
    });
    //   customizer
    const colorcustomizerMode = sessionStorage.getItem('color-customizer-mode');
    const colorcustomizerinfoMode = sessionStorage.getItem(
      'colorcustominfo-mode'
    );
    const colorcustomizerprimaryMode = sessionStorage.getItem(
      'colorcustomprimary-mode'
    );
    if (colorcustomizerMode === null) {
      props.ColorCustomizerAction(
        props.customizerMode,
        props.cololrinfomode,
        props.colorprimarymode
      );
      document.documentElement.style.setProperty(
        '--bs-info',
        props.cololrinfomode
      );
    } else {
      props.ColorCustomizerAction(
        colorcustomizerMode,
        colorcustomizerinfoMode,
        colorcustomizerprimaryMode
      );
      document.documentElement.style.setProperty(
        '--bs-info',
        colorcustomizerinfoMode
      );
    }
  });


  // Initial Render
  useEffect(() => {
    Get_All_Years(null)
      .then((result) => {
        console.log("================> Years ")
        console.log(result.data)
        setYears(result.data)
        // setCurrentYear(2022)
      })
    Get_Bedroom_Count(null)
      .then((result) => {
        console.log("================> Bedroom_Count ")
        setBedroomCount(result.data)
      })
    Get_Sleeping_Bed_Count(null)
      .then((result) => {
        console.log("================> Sleeping_Bed_Count ")
        setSleepingBedCount(result.data)
      })
  }, [])

  useEffect(() => {
    Get_Bookings_Stats().then((res) => {
      let arr = res.data.filter((item) => {
        return item._id.date_cord.year == '2022';
      });
      setyearStats(arr);
      setOldData(arr);
    });
    Get_Bookings_Sources_Stats().then((stats) => {
      let x = stats.data.map((Element) => {
        return Element.reservation;
      });
      let y = stats.data.map((Element) => {
        return Element._id;
      });
      SetSourceStats(y);
      Setreservations(x);
    });
  }, []);

  useEffect(() => {
    Get_all_Filter_Rentals().then((res) => {
      const rentals = res.data.Rentals;
      rentals.sort(function (a, b) {
        return a.name.localeCompare(b.name)
      });
      console.log("===========Rentals============")
      console.log(rentals)
      setRentalsOption(rentals)
    });
  }, []);

  // Query Fetch
  useEffect(() => {
    setLoading(true)
    console.log("============query==============")
    console.log(query)
    Get_Main_Global_Stats(query)
      .then((result) => {
        SetGlobalStat(result.data)
        console.log("============response==============")
        console.log(result.data)
        setLoading(false)
      });
    Get_All_Adresses(query)
      .then((result) => {
        setAdressList(result.data)
      })
    Get_Occupation_Rate(query)
      .then((result) => {
        console.log("================>> OCCUPATION DATA")
        console.log(result.data)
        setOccupationRate(result.data['mean_occupation'])
      })
  }, [query]);

  useEffect(() => {
    console.log(parseInt(GlobalStat[0]?.montant_total_encaissé) / GlobalStat[0]?.nb_nights_total);
    setAveragePrice(parseFloat(parseFloat(GlobalStat[0]?.montant_total_encaissé) / GlobalStat[0]?.nb_nights_total).toFixed(0))
  }, [GlobalStat])

  useEffect(() => {
    if (years.length > 0) {
      var currentYearIndex = 0
      years.forEach((year, index) => {
        if (year == currentYear) currentYearIndex = index
      })
      setYearFilter(currentYearIndex + 1)
    }
  }, [years])

  function Stringify(num) {
    const data = num.toString()
    var output = ""
    var counter = 0
    for (let i = data.length - 1; i >= 0; i--) {
      if (counter % 3 != 0 || i == data.length - 1) {
        output = data[i] + output
      }
      else {
        output = data[i] + "," + output
      }
      counter++
    }
    return output
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const resetFilter = () => {
    setisFiltred(false);
    setStartDate('');
    setEndDate('');
    setValue([]);
    setBedroomCountValue(0)
    setSleepingBedCountValue(0)
    setAdressValue([])
    SetSources([
      'Airbnb',
      'Booking.com',
      'homesuitehome.com',
      'Réservation Direct',
    ]);
    setyearStats(OldData);
    handleClose();
  };


  // let series = [100 * occupationRate];

  const handleFilter = () => {
    setisLoading(true);
    // console.log('handleFilter', Sources, value, StartDate === '', EndDate);
    let temp_query = {};
    if (Sources.length !== 4 && Sources.length !== 0) {
      temp_query.source = { $in: query }
      SetSources([])
    }
    if (StartDate !== '') {
      let start = new Date(StartDate);
      let end = new Date(EndDate);
      temp_query.start_at = { $gte: start.toISOString(), $lte: end.toISOString() };
    } else {
      const currentYearStart = new Date(`01 January ${year} 00:00 UTC`)
      const currentYearEnd = new Date(`31 December ${year} 23:59 UTC`)
      temp_query.start_at = { $gte: currentYearStart.toISOString(), $lte: currentYearEnd.toISOString() }
      setStartDate('')
    }

    if (value.length > 0) {
      let prev = query.rental_id ? query.rental_id["$in"] : []
      temp_query.rental_id = { $in: [...value, ...prev] };
      setValue([])
    }

    if (BedroomCountValue > 0) {
      temp_query.bedrooms_count = { $in: BedroomCountValue };
      setBedroomCountValue(0)
    }
    if (SleepingBedCountValue > 0) {
      temp_query.sleeps = { $in: SleepingBedCountValue };
      setSleepingBedCountValue(0)
    }
    if (AdressValue.length > 0) {
      let prev = query.adresse_finale ? query.adresse_finale["$in"] : []
      temp_query.adresse_finale = { $in: [...AdressValue, ...prev] };
      setAdressValue([])
    }
    setQuery(temp_query);
    handleClose();
    setisLoading(false);
  };

  const handle_Sources = (value) => {
    let Arr = Sources;
    if (Sources.indexOf(value) !== -1) {
      Arr.splice(Sources.indexOf(value), 1);
    } else {
      Arr.push(value);
    }
    SetSources([...Arr]);
  };

  const handle_end_date = (value) => {
    setEndDate(value);
  };

  const handle_start_date = (value) => {
    setStartDate(value);
  };

  const handleYearChange = (event) => {
    setYearFilter(event.target.value);
    setCurrentYear(years[event.target.value - 1])
    const currentYearStart = new Date(`01 January ${years[event.target.value - 1]} 00:00 UTC`)
    const currentYearEnd = new Date(`31 December ${years[event.target.value - 1]} 23:59 UTC`)
    setQuery({ start_at: { $gte: currentYearStart.toISOString(), $lte: currentYearEnd.toISOString() } })
  };

  const handleDelete = () => {
    console.info('You clicked the delete icon.');
  };


  useEffect(() => {
    let FilterObject = {}
    if (query.source) {
      FilterObject['source'] = query.source.$in.map((element, index) => {
        return {
          db_field: 'source',
          label: element,
          index: index
        }
      })
    }
    if (query.start_at) {
      FilterObject['start_at'] = [{
        db_field: 'start_at',
        label: `Début : ${query.start_at.$gte.substring(0, 10)} Fin : ${query.start_at.$lte.substring(0, 10)}`,
        index: 0
      }]
    }
    if (query.rental_id) {
      FilterObject['rental_id'] = query.rental_id.$in.map((element, index) => {
        var name = ""
        RentalsOptions.forEach((rental) => {
          if (rental.id == element) name = rental.name
        })
        return {
          db_field: 'rental_id',
          label: name,
          rental_id: element,
          index: index
        }
      })
    }
    if (query.bedrooms_count) {
      FilterObject['bedrooms_count'] = [{
        db_field: 'bedrooms_count',
        label: `Nb Chambres : ${query.bedrooms_count.$in}`,
        value: query.bedrooms_count.$in,
        index: 0
      }]
    }
    if (query.sleeps) {
      FilterObject['sleeps'] = [{
        db_field: 'sleeps',
        label: `Nb Couchages : ${query.sleeps.$in}`,
        value: query.bedrooms_count.$in,
        index: 0
      }]
    }
    if (query.adresse_finale) {
      FilterObject['adresse_finale'] = query.adresse_finale.$in.map((element, index) => {
        return {
          db_field: 'adresse_finale',
          label: element,
          index: index
        }
      })
    }
    var FilteredArray = []
    for (const prop in FilterObject) {
      FilterObject[prop].forEach((element) => {
        FilteredArray.push(element)
      })
    }
    console.log("===============> Filtered Array")
    console.log(FilteredArray)
    setQueryFilterArray(FilteredArray)
  }, [query])

  const Filter_bar = () => {
    return (
      <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>
          <DialogTitle id='alert-dialog-title'>
            {' '}
            {' Menu Filter'}
            <FilterListIcon />
          </DialogTitle>

          <DialogContent style={{ height: '600px' }}>
            {isLoading ? (
              <Grid
                item
                md={12}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <ClipLoader
                  size={150}
                  aria-label='Loading Spinner'
                  data-testid='loader'
                />
              </Grid>
            ) : (
              <>

                <Grid item xs={12} style={{}}>
                  <DialogContentText id='alert-dialog-description'>
                    Locations{ }
                  </DialogContentText>
                  <Autocomplete
                    multiple
                    id='tags-outlined'
                    options={RentalsOptions}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                      setValue(
                        newValue.map((option) => parseInt(option.id) || option)
                      );
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value
                    }
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField {...params} placeholder='Locations' />
                    )}
                  />
                </Grid>

                <Grid item xs={12} style={{}}>
                  <DialogContentText id='alert-dialog-description'>
                    Adresse{ }
                  </DialogContentText>
                  <Autocomplete
                    multiple
                    id='tags-outlined'
                    options={AdressList}
                    getOptionLabel={(option) => option}
                    onChange={(event, newValue) => {
                      setAdressValue(
                        newValue.map((option) => parseInt(option.id) || option)
                      );
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value
                    }
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField {...params} placeholder='Adresse' />
                    )}
                  />
                </Grid>

                <Grid item xs={12} style={{}}>
                  <DialogContentText id='alert-dialog-description'>
                    Nombre De Chambres{ }
                  </DialogContentText>
                  <Autocomplete
                    id='tags-outlined'
                    options={BedroomCount}
                    getOptionLabel={(option) => option}
                    onChange={(event, newValue) => {
                      setBedroomCountValue(newValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value
                    }
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField {...params} placeholder='Nombre De Chambres' />
                    )}
                  />
                </Grid>

                <Grid item xs={12} style={{}}>
                  <DialogContentText id='alert-dialog-description'>
                    Nombre De Couchages{ }
                  </DialogContentText>
                  <Autocomplete
                    id='tags-outlined'
                    options={SleepingBedCount}
                    getOptionLabel={(option) => option}
                    onChange={(event, newValue) => {
                      setSleepingBedCountValue(newValue)
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value
                    }
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField {...params} placeholder='Nombre De Couchages' />
                    )}
                  />
                </Grid>

                <Grid item xs={12} style={{}}>
                  <DialogContentText id='alert-dialog-description'>
                    Dates{' '}
                  </DialogContentText>
                  <Calenders
                    style={{ width: '20%' }}
                    handle_end_date={handle_end_date}
                    handle_start_date={handle_start_date}
                    startDate={StartDate}
                    endDate={EndDate}
                    minDate={mindDate}></Calenders>
                </Grid>
                <DialogContentText id='alert-dialog-description'>
                  Sources{' '}
                </DialogContentText>

                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <div class='form-element'>
                      <input
                        type='checkbox'
                        name='platform'
                        value='Airbnb'
                        id='airbnb'
                        checked={Sources.includes('Airbnb')}
                        onChange={(e) => {
                          handle_Sources(e.target.value);
                        }}
                      />
                      <label for='airbnb'>
                        <div class='icon'>
                          <div class='title'>Airbnb</div>
                        </div>
                      </label>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    {' '}
                    <div class='form-element'>
                      <input
                        type='checkbox'
                        name='platform'
                        value='Booking.com'
                        id='booking'
                        checked={Sources.includes('Booking.com')}
                        onChange={(e) => {
                          handle_Sources(e.target.value);
                        }}
                      />
                      <label for='booking'>
                        <div class='icon'>
                          <div class='title'>Booking.com</div>
                        </div>
                      </label>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    {' '}
                    <div class='form-element'>
                      <input
                        type='checkbox'
                        name='platform'
                        value='homesuitehome.com'
                        id='HomeSuiteHome'
                        checked={Sources.includes('homesuitehome.com')}
                        onChange={(e) => {
                          handle_Sources(e.target.value);
                        }}
                      />
                      <label for='HomeSuiteHome'>
                        <div class='icon'>
                          <div class='title'>Home Suite Home</div>
                        </div>
                      </label>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    {' '}
                    <div class='form-element'>
                      <input
                        type='checkbox'
                        name='platform'
                        value={'Réservation Direct'}
                        checked={Sources.includes('Réservation Direct')}
                        onChange={(e) => {
                          handle_Sources(e.target.value);
                        }}
                        id='RéservationDirect'
                      />
                      <label for='RéservationDirect'>
                        <div class='icon'>
                          <div class='title'>Réservation Direct</div>
                        </div>
                      </label>
                    </div>
                  </Grid>
                </Grid>
              </>
            )}
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>

            <Button onClick={resetFilter}>Reset</Button>
            <Button onClick={handleFilter} autoFocus>
              Appliquer
            </Button>
          </DialogActions>
        </Dialog>
        <Col>
          <FormControl>
            <InputLabel id='demo-simple-select-label'>Année</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={yearFilter}
              label='Année'
              onChange={handleYearChange}>
              {years.map((element, index) => (
                <MenuItem value={index + 1}>{element}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Col>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end' }} >
          <Button
            style={{
              width: '9%',
              margin: '10px',
              display: 'flex',
              justifyContent: 'space-around',
              backgroundColor: '#3a57e8',
            }}
            onClick={handleClickOpen}
            variant='primary  '>
            <span className='btn-inner'>
              <FilterListIcon />
              Filtrer
            </span>
          </Button>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap' }} style={{ width: '50%' }}>
            {
              queryFilterArray.map((element) => {
                return <CustomChip
                  label={element.label}
                  element={element}
                  FilteredArray={queryFilterArray}
                  setFilteredArray={setQueryFilterArray}
                  query={query}
                  setQuery={setQuery}
                  resetFilter={resetFilter}>
                </CustomChip>
              })
            }
          </Box>
        </Box>
      </Row >
    );
  };


  return (
    <>
      <Row style={{ marginTop: '100px' }}>
        <Row>
          {Filter_bar()}
        </Row>
        {Loading ?
          (<Box sx={{ display: "flex", width: '100%', height: '70vh', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>) :
          (
            <>
              <br />
              <Col md='12' lg='12' style={{ marginTop: '50px' }}>
                <Row className='row-cols-1'>
                  <div className='overflow-hidden d-slider1 '>
                    <Swiper
                      className='p-0 m-0 mb-2 list-inline '
                      slidesPerView={3}
                      spaceBetween={40}
                      navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                      }}
                      breakpoints={{
                        320: { slidesPerView: 1 },
                        550: { slidesPerView: 2 },
                        991: { slidesPerView: 3 },
                        1400: { slidesPerView: 3 },
                        1500: { slidesPerView: 3 },
                        1920: { slidesPerView: 3 },
                        2040: { slidesPerView: 3 },
                        2440: { slidesPerView: 3 },
                      }}
                      data-aos='fade-up'
                      data-aos-delay='700'>
                      <SwiperSlide className='card card-slide'>
                        <div className='card-body'>
                          <div className='progress-widget'>
                            <Circularprogressbar
                              stroke={props.colorprimarymode}
                              width='60px'
                              height='60px'
                              Linecap='rounded'
                              trailstroke='#ddd'
                              strokewidth='4px'
                              style={{ width: 60, height: 60 }}
                              value={90}
                              id='circle-progress-01'>
                              <svg
                                className=''
                                width='24'
                                height='24px'
                                viewBox='0 0 24 24'>
                                <path
                                  fill='currentColor'
                                  d='M5,17.59L15.59,7H9V5H19V15H17V8.41L6.41,19L5,17.59Z'
                                />
                              </svg>
                            </Circularprogressbar>
                            <div className='progress-detail'>
                              <p className='mb-2'>Volume d'affaire Total</p>
                              <h4 className='counter'>
                                {Stringify(GlobalStat[0] ? Math.trunc(GlobalStat[0]?.montant_total_encaissé) : 0)}€
                              </h4>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className=' card card-slide'>
                        <div className='card-body'>
                          <div className='progress-widget'>
                            <Circularprogressbar
                              stroke={props.cololrinfomode}
                              width='60px'
                              height='60px'
                              trailstroke='#ddd'
                              strokewidth='4px'
                              Linecap='rounded'
                              style={{ width: 60, height: 60 }}
                              value={60}
                              id='circle-progress-02'>
                              <svg
                                className=''
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'>
                                <path
                                  fill='currentColor'
                                  d='M19,6.41L17.59,5L7,15.59V9H5V19H15V17H8.41L19,6.41Z'
                                />
                              </svg>
                            </Circularprogressbar>
                            <div className='progress-detail'>
                              <p className='mb-2'>Commission TTC</p>
                              <h4 className='counter'>
                                {Stringify(GlobalStat[0] ? Math.trunc(GlobalStat[0]?.commission_ttc) : 0)}€
                              </h4>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className=' card card-slide'>
                        <div className='card-body'>
                          <div className='progress-widget'>
                            <Circularprogressbar
                              stroke={props.cololrinfomode}
                              width='60px'
                              height='60px'
                              trailstroke='#ddd'
                              strokewidth='4px'
                              Linecap='rounded'
                              style={{ width: 60, height: 60 }}
                              value={60}
                              id='circle-progress-02'>
                              <svg
                                className=''
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'>
                                <path
                                  fill='currentColor'
                                  d='M19,6.41L17.59,5L7,15.59V9H5V19H15V17H8.41L19,6.41Z'
                                />
                              </svg>
                            </Circularprogressbar>
                            <div className='progress-detail'>
                              <p className='mb-2'>Commission HT</p>
                              <h4 className='counter'>
                                {Stringify(GlobalStat[0] ? Math.trunc(GlobalStat[0]?.commission_ttc / 1.2) : 0)}€
                              </h4>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>

                      <SwiperSlide className=' card card-slide'>
                        <div className='card-body'>
                          <div className='progress-widget'>
                            <Circularprogressbar
                              stroke={props.cololrinfomode}
                              width='60px'
                              height='60px'
                              trailstroke='#ddd'
                              strokewidth='4px'
                              Linecap='rounded'
                              style={{ width: 60, height: 60 }}
                              value={60}
                              id='circle-progress-02'>
                              <svg
                                className=''
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'>
                                <path
                                  fill='currentColor'
                                  d='M19,6.41L17.59,5L7,15.59V9H5V19H15V17H8.41L19,6.41Z'
                                />
                              </svg>
                            </Circularprogressbar>
                            <div className='progress-detail'>
                              <p className='mb-2'>Frais de ménages encaissés en TTC</p>
                              <h4 className='counter'>
                                {Stringify(GlobalStat[0] ? Math.trunc(GlobalStat[0]?.frais_de_ménages_encaissés) : 0)}€
                              </h4>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className=' card card-slide'>
                        <div className='card-body'>
                          <div className='progress-widget'>
                            <Circularprogressbar
                              stroke={props.cololrinfomode}
                              width='60px'
                              height='60px'
                              trailstroke='#ddd'
                              strokewidth='4px'
                              Linecap='rounded'
                              style={{ width: 60, height: 60 }}
                              value={60}
                              id='circle-progress-02'>
                              <svg
                                className=''
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'>
                                <path
                                  fill='currentColor'
                                  d='M19,6.41L17.59,5L7,15.59V9H5V19H15V17H8.41L19,6.41Z'
                                />
                              </svg>
                            </Circularprogressbar>
                            <div className='progress-detail'>
                              <p className='mb-2'>Frais de ménages encaissés en HT</p>
                              <h4 className='counter'>
                                {Stringify(GlobalStat[0] ? Math.trunc(GlobalStat[0]?.frais_de_ménages_encaissés / 1.2) : 0)}€
                              </h4>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className=' card card-slide'>
                        <div className='card-body'>
                          <div className='progress-widget'>
                            <Circularprogressbar
                              stroke={props.colorprimarymode}
                              width='60px'
                              height='60px'
                              trailstroke='#ddd'
                              strokewidth='4px'
                              Linecap='rounded'
                              style={{ width: 60, height: 60 }}
                              value={70}
                              id='circle-progress-03'>
                              <svg className='' width='24' viewBox='0 0 24 24'>
                                <path
                                  fill='currentColor'
                                  d='M19,6.41L17.59,5L7,15.59V9H5V19H15V17H8.41L19,6.41Z'
                                />
                              </svg>
                            </Circularprogressbar>
                            <div className='progress-detail'>
                              <p className='mb-2'>Cout de revient ménage en TTC</p>
                              <h4 className='counter'>
                                {Stringify(GlobalStat[0] ? Math.trunc(GlobalStat[0]?.cout_de_revient_ménage) : 0)}€
                              </h4>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className=' card card-slide'>
                        <div className='card-body'>
                          <div className='progress-widget'>
                            <Circularprogressbar
                              stroke={props.colorprimarymode}
                              width='60px'
                              height='60px'
                              trailstroke='#ddd'
                              strokewidth='4px'
                              Linecap='rounded'
                              style={{ width: 60, height: 60 }}
                              value={70}
                              id='circle-progress-03'>
                              <svg className='' width='24' viewBox='0 0 24 24'>
                                <path
                                  fill='currentColor'
                                  d='M19,6.41L17.59,5L7,15.59V9H5V19H15V17H8.41L19,6.41Z'
                                />
                              </svg>
                            </Circularprogressbar>
                            <div className='progress-detail'>
                              <p className='mb-2'>Cout de revient ménage en HT</p>
                              <h4 className='counter'>
                                {Stringify(GlobalStat[0] ? Math.trunc(GlobalStat[0]?.cout_de_revient_ménage / 1.2) : 0)}€
                              </h4>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className=' card card-slide'>
                        <div className='card-body'>
                          <div className='progress-widget'>
                            <Circularprogressbar
                              stroke={props.colorprimarymode}
                              width='60px'
                              height='60px'
                              trailstroke='#ddd'
                              strokewidth='4px'
                              Linecap='rounded'
                              style={{ width: 60, height: 60 }}
                              value={70}
                              id='circle-progress-03'>
                              <svg className='' width='24' viewBox='0 0 24 24'>
                                <path
                                  fill='currentColor'
                                  d='M19,6.41L17.59,5L7,15.59V9H5V19H15V17H8.41L19,6.41Z'
                                />
                              </svg>
                            </Circularprogressbar>
                            <div className='progress-detail'>
                              <p className='mb-2'>Revenu brut HSH</p>
                              <h4 className='counter'>
                                {/* <CountUp
                      start={0}
                      end={GlobalStat[0]?.revenu_brut_hsh}
                      duration={6}
                    /> */}
                                {Stringify(GlobalStat[0] ? Math.trunc(GlobalStat[0]?.revenu_brut_hsh) : 0)}€
                              </h4>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className=' card card-slide'>
                        <div className='card-body'>
                          <div className='progress-widget'>
                            <Circularprogressbar
                              stroke={props.cololrinfomode}
                              width='60px'
                              height='60px'
                              trailstroke='#ddd'
                              strokewidth='4px'
                              Linecap='rounded'
                              style={{ width: 60, height: 60 }}
                              value={60}
                              id='circle-progress-04'>
                              <svg
                                className=''
                                width='24px'
                                height='24px'
                                viewBox='0 0 24 24'>
                                <path
                                  fill='currentColor'
                                  d='M5,17.59L15.59,7H9V5H19V15H17V8.41L6.41,19L5,17.59Z'
                                />
                              </svg>
                            </Circularprogressbar>
                            <div className='progress-detail'>
                              <p className='mb-2'>Revenu net encaissé en TTC</p>
                              <h4 className='counter'>
                                {Stringify(GlobalStat[0] ? Math.trunc(GlobalStat[0]?.revenu_net_encaissé) : 0)}€
                              </h4>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className=' card card-slide'>
                        <div className='card-body'>
                          <div className='progress-widget'>
                            <Circularprogressbar
                              stroke={props.cololrinfomode}
                              width='60px'
                              height='60px'
                              trailstroke='#ddd'
                              strokewidth='4px'
                              Linecap='rounded'
                              style={{ width: 60, height: 60 }}
                              value={60}
                              id='circle-progress-04'>
                              <svg
                                className=''
                                width='24px'
                                height='24px'
                                viewBox='0 0 24 24'>
                                <path
                                  fill='currentColor'
                                  d='M5,17.59L15.59,7H9V5H19V15H17V8.41L6.41,19L5,17.59Z'
                                />
                              </svg>
                            </Circularprogressbar>
                            <div className='progress-detail'>
                              <p className='mb-2'>Revenu net encaissé en HT</p>
                              <h4 className='counter'>
                                {Stringify(GlobalStat[0] ? Math.trunc(GlobalStat[0]?.revenu_net_encaissé / 1.2) : 0)}€
                              </h4>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>

                      <div className='swiper-button swiper-button-next'></div>
                      <div className='swiper-button swiper-button-prev'></div>
                    </Swiper>
                  </div>
                </Row>
              </Col>
              <Col md='12' lg='12'>
                <Row>
                  <Col md={isFiltred ? '12' : '12'} style={{ marginTop: '10px' }}>
                    <ApexCharts
                      title={"Chiffre d'affaires"}
                      series={[
                        'chiffre_affaires_total',
                        'chiffre_affaires_total_net',
                      ]}
                      names={["Chiffre d'affaires total", "Revenu net"]}
                      type='line'
                      query={query}
                      setQuery={setQuery}
                      currentYear={currentYear}
                      setCurrentYear={setCurrentYear}
                    />
                  </Col>

                  <Col md={isFiltred ? '12' : '6'} style={{ marginTop: '10px' }}>
                    <ApexCharts
                      title={'Commissions  '}
                      series={['commission_hsh_total']}
                      names={['commissions  ']}
                      type='area'
                      query={query}
                      setQuery={setQuery}
                      currentYear={currentYear}
                      setCurrentYear={setCurrentYear}
                    />
                  </Col>

                  <Col md={isFiltred ? '12' : '6'} style={{ marginTop: '10px' }}>
                    <ApexCharts
                      title={'Frais de ménage'}
                      series={['cout_menaage_total', 'Gain_sur_menage_total']}
                      names={['Cout de ménage', 'Gain sur ménage']}
                      type='line'
                      query={query}
                      setQuery={setQuery}
                      currentYear={currentYear}
                      setCurrentYear={setCurrentYear}
                    />
                  </Col>

                  <Col md='12' style={{ marginTop: '10px' }}>
                    <ApexCharts
                      title={`Volume d'affaires total`}
                      series={['Volume_affaire_total']}
                      names={[`Volume d'affaire total`]}
                      type='bar'
                      query={query}
                      setQuery={setQuery}
                      currentYear={currentYear}
                      setCurrentYear={setCurrentYear}
                    />
                  </Col>

                  <Col md={isFiltred ? '12' : '6'} style={{ marginTop: '10px' }} >
                    <ApexCharts
                      title={'Nombre de nuits'}
                      series={['nb_nights_total']}
                      names={['Nombres de nuits']}
                      type='line'
                      query={query}
                      setQuery={setQuery}
                      currentYear={currentYear}
                      setCurrentYear={setCurrentYear}
                    />
                  </Col>

                  <Col md={isFiltred ? '12' : '6'} style={{ marginTop: '10px' }}>
                    <ApexCharts
                      title={'Réservation'}
                      series={['reservation']}
                      names={['réservations']}
                      type='bar'
                      query={query}
                      setQuery={setQuery}
                      currentYear={currentYear}
                      setCurrentYear={setCurrentYear}
                    />
                  </Col>

                  <Col md={isFiltred ? '12' : '6'} style={{ marginTop: '10px' }}>
                    <ApexCharts
                      title={'Montant soumis à commission total'}
                      series={['montant_commissionable_total']}
                      names={['montant soumis à commission total']}
                      type='area'
                      query={query}
                      setQuery={setQuery}
                      currentYear={currentYear}
                      setCurrentYear={setCurrentYear}
                    />
                  </Col>

                  <Col md={3} style={{ marginTop: '10px' }}>
                    <div
                      class='box shadow'
                      style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',

                      }}>
                      <Typography variant="h6" align="center" sx={{ color: "#999999" }}>
                        Taux d'occupation
                      </Typography>
                      <hr />
                      <div style={{ width: '50%', height: '50%' }}>
                        <CircularProgressbar
                          value={occupationRate}
                          text={`${occupationRate}%`}
                          styles={buildStyles({
                            textColor: "#3a57e8",
                            pathColor: "#3a57e8",
                            trailColor: "#E1E5FB",
                            strokeLinecap: "butt"
                          })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={3} style={{ marginTop: '10px' }}>
                    <div
                      class='box shadow'
                      style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',

                      }}>
                      <Typography variant="h6" align="center" sx={{ color: "#999999" }}>
                        Prix moyen
                      </Typography>
                      <hr />
                      <div style={{ width: '50%', height: '50%' }}>
                        <CircularProgressbar
                          value={0}
                          text={`${averagePrice}€`}
                          styles={buildStyles({
                            textColor: "#3a57e8",
                            pathColor: "#3a57e8",
                            trailColor: "#E1E5FB",
                            strokeLinecap: "butt"
                          })}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </>)
        }
      </Row >
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
