/** @format */

import React from 'react';
import {
  Page,
  Document,
  StyleSheet,
  Image,
  Text,
  View,
} from '@react-pdf/renderer';
import NiceLogo from '../../../images/NiceLogo.jpeg';
import InvoiceTitle from './InvoiceTitle';
import { padding, width } from '@mui/system';
import { Directions } from '@mui/icons-material';
import { useEffect } from 'react';
const styles = StyleSheet.create({
  table: {
    display: 'table',
    width: '100%',
    borderStyle: 'solid',
    borderColor: '#D3D3D3',
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    borderStyle: 'solid',
    borderColor: '#D3D3D3',
    flexDirection: 'row',
    borderWidth: 1,
    fontSize: '3px',
  },
  tableRowMain: {
    margin: 'auto',
    borderStyle: 'solid',
    borderColor: '#D3D3D3',
    flexDirection: 'row',
    borderWidth: 1,
    fontSize: '3px',
    backgroundColor: '#D3D3D3',
    fontWeight: 'bold'
  },
  tableCol: {
    width: '12%',
    borderStyle: 'solid',
    borderColor: '#D3D3D3',
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 'auto',
    marginTop: 5,
    fontSize: '9px',
    fontWight: 'bold',
    padding: '5px',
    AlignText: 'center',
  },
  page: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2%',
  },
  logo: {
    width: 220,
    marginTop: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  MainHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: 'Helvetica',
    fontSize: '9px',
    width: '100%',
    flexDirection: 'row',
    padding: '3% 5%',
  },
  ContentHeaderLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    fontWeight: 'bold',
    height: 80,
  },
  ContentHeaderRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    fontWeight: 'bold',
    height: 80,
  },
  breakable: {
    width: '100%',
    height: 110,
    border: 'none !important',
  },
  dateTitleContainer: {
    marginTop: 30,
  },
  dateTitleHeader: {
    letterSpacing: 4,
    fontSize: 16,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  centerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: 10,
    paddingTop: 60,
  },
  singleValueContainer: {
    padding: 8,
    border: '1px solid #D3D3D3'
  },
  singleValueHeader: {
    fontSize: '12px',
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: 10,
    height: 800,
    bottom: 0,
  },
  bottomFooterContainer: {
    marginTop: 50,
    padding: 10,
    backgroundColor: '#D3D3D3',
    color: '#5A5A5A',
    fontFamily: 'Helvetica',
    fontSize: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 70,
    width: 800
  }
});


const InvoiceHeader = (props, number) => (
  <div style={styles.MainHeader}>
    <View style={styles.ContentHeaderRight}>
      <Text>HSH RENTAL</Text>
      <Text>81 rue d’Amsterdam</Text>
      <Text>75008 Paris</Text>
      <Text>06.13.31.13.83</Text>
      <Text>contact@homesuitehome.fr</Text>
      <Text>SIREN: 891 730 848</Text>
    </View>
    <View style={styles.ContentHeaderLeft}>
      <Text>Facture HSH {number}</Text>
      <Text>Date: {new Date().toISOString().substring(0, 10)} </Text>
      <Text>Appartement: {props?.Rental.name}</Text>
      <Text>{props?.Rental['Propriétaire Facturation']}</Text>
      <Text>{props?.Rental['Adresse de Facturation ']}</Text>
      <Text>
        {props?.Rental['Zip Facturation']}
      </Text>
    </View>
  </div>
);

const PdfDocument = (props) => {
  useEffect(() => {
    console.log("============> PDF PROPS")
    console.log(props)
  }, [])
  const calculer_total_nbe_jours = () => {
    let sum = 0;
    props.Data.map((item) => {
      sum = sum + parseInt(item.nb_nights);
    });
    return parseFloat(sum).toFixed(2);
  };

  const calculer_total_Montant_Encaisse = () => {
    let sum = 0;
    props.Data.map((item) => {
      sum = sum + parseFloat(item.hsh);
    });
    return parseFloat(sum).toFixed(2);
  };
  const calculer_total_Montant_Menage = () => {
    let sum = 0;
    props.Data.map((item) => {
      sum = sum + parseFloat(item['Prix Menage Facturé TTC']);
    });
    return parseFloat(sum).toFixed(2);
  };
  const calculer_total_Montant_Commission_ = () => {
    let sum = 0;
    props.Data.map((item) => {
      sum = sum + parseFloat(item.montant_commissionable);
    });
    return parseFloat(sum).toFixed(2);
  };

  const calculer_total_commisssion = () => {
    let sum = 0;
    props.Data.map((item) => {
      sum = sum + parseFloat(item.commission_hsh);
    });
    return parseFloat(sum).toFixed(2);
  };

  const calculer_tva = (numb) => {
    return numb * 0.2 / 1.2;
  };

  const calculer_ht = (num) => {
    return num - calculer_tva(num);
  };

  const calculer_ht_total = () => {
    return (
      calculer_ht(calculer_total_Montant_Menage()) +
      calculer_ht(calculer_total_commisssion())
    );
  };

  const calculer_tva_total = () => {
    return (
      calculer_tva(calculer_total_Montant_Menage()) +
      calculer_tva(calculer_total_commisssion())
    );
  };

  const calculer_total = () => {
    return (
      parseFloat(calculer_total_Montant_Menage(), 10) +
      parseFloat(calculer_total_commisssion(), 10)
    );
  };
  return (
    <Document>
      <Page>
        <Image style={styles.logo} src={NiceLogo} />
        {/* <InvoiceTitle title={'Home Suite Home'} /> */}
        <View style={styles.dateTitleContainer}>
          <Text style={styles.dateTitleHeader}>{`Facture ${props.month}`}</Text>
        </View>
        {InvoiceHeader(props, props.number)}
        <View style={styles.page}>
          <View style={styles.table}>
            <View style={styles.tableRowMain}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Plateforme</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Date d’entrée</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Nb nuit</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Montant total encaissé</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Ménage</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Montant ménage en €</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  Montant soumis à commission en €
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Commission En € TTC</Text>
              </View>
            </View>
            {props?.Data.map((item, index) => {
              if ((index % 46 == 0 && index > 0) || index == 19) {
                return (
                  <View>
                    <View style={styles.breakable} />

                    <View style={styles.tableRow}>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{item.source}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {item.start_at.substr(0, 10)}{' '}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{item.nb_nights}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {parseFloat(item.hsh).toFixed(2)}
                          {' €'}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{'HSH'}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {item['Prix Menage Facturé TTC']}
                          {' €'}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {parseFloat(item.montant_commissionable).toFixed(2)}
                          {' €'}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {' '}
                          {parseFloat(item.montant_commissionable).toFixed(2)}
                          {'€'}
                        </Text>
                      </View>
                    </View>
                  </View>
                );
              } else {
                return (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{item.source}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {item.start_at.substr(0, 10)}{' '}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{item.nb_nights}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {parseFloat(item.hsh).toFixed(2)}
                        {' €'}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{'HSH'}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {item['Prix Menage Facturé TTC']}
                        {' €'}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {parseFloat(item.montant_commissionable).toFixed(2)}
                        {' €'}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {' '}
                        {parseFloat(item.commission_hsh).toFixed(2)}
                        {'€'}
                      </Text>
                    </View>
                  </View>
                );
              }
            })}

            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Total</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {props.Data.length > 0 && calculer_total_nbe_jours()}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {props.Data.length > 0 &&
                    parseFloat(calculer_total_Montant_Encaisse()).toFixed(2) +
                    ' €'}
                  {/* {total_montant_enc.length > 0 && Calculate_enc_somme() + '€'} */}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {parseFloat(calculer_total_Montant_Menage()).toFixed(2) +
                    ' €'}
                  {/* {rent &&
                    parseInt(Get_Cleaning_Fee(rent)) * props.Data.length + '€'} */}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {parseFloat(calculer_total_Montant_Commission_()).toFixed(2) +
                    ' €'}
                  {/* {total_montant && parseFloat(total_montant).toFixed(2) + '€'} */}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {parseFloat(calculer_total_commisssion()).toFixed(2) + ' €'}
                  {/* {total_comission &&
                    parseFloat(total_comission).toFixed(2) + '€'} */}
                </Text>
              </View>
            </View>
          </View>

        </View>
      </Page>

      <Page>
        <View style={styles.footerContainer}>
          <View style={styles.centerContainer}>
            <InvoiceTitle title={'Récapitulatif'} />
            <View style={styles.table}>
              <View style={styles.tableRowMain}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}></Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Montant HT</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Montant TVA</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Montant TTC</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Commission Gestion</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {parseFloat(calculer_ht(calculer_total_commisssion())).toFixed(2)}
                    {' €'}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {parseFloat(calculer_tva(calculer_total_commisssion())).toFixed(2)}
                    {' €'}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {parseFloat(calculer_total_commisssion()).toFixed(2)}
                    {' €'}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Ménage</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {parseFloat(calculer_ht(calculer_total_Montant_Menage())).toFixed(2)}
                    {' €'}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {parseFloat(calculer_tva(calculer_total_Montant_Menage())).toFixed(2)}
                    {' €'}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {parseFloat(calculer_total_Montant_Menage()).toFixed(2)}
                    {' €'}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Montant total à payer</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {parseFloat(calculer_ht_total()).toFixed(2)}
                    {' €'}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {parseFloat(calculer_tva_total()).toFixed(2)}
                    {' €'}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {parseFloat(calculer_total()).toFixed(2)}
                    {' €'}
                  </Text>
                </View>
              </View>
            </View>
            <InvoiceTitle title={'Resultat Net Client'} />
            <View style={styles.singleValueContainer}>
              <Text style={styles.singleValueHeader}>{`${parseFloat(parseFloat(calculer_total_Montant_Encaisse()).toFixed(2) - calculer_total()).toFixed(2)} €`}</Text>
            </View>
          </View>
          <View style={styles.centerContainer}>
            <View style={styles.bottomFooterContainer}>
              <Text>Coordonnées bancaires :</Text>
              <Text>Titulaire : HSH RENTAL</Text>
              <Text>IBAN : FR76 3006 6100 3100 0202 5620 201</Text>
              <Text>BIC : CMCIFRPP</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PdfDocument;
