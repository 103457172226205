/** @format */

import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Get_Data_by_Month } from '../../../api/action_booking.js';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import Button from '@mui/material/Button';

const Apexchart = (props) => {
  const [yearStats, setyearStats] = useState([]);
  const [OldData, setOldData] = useState([]);
  const [filter, setFilter] = useState(0);
  const [months, setMonths] = useState([
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mais',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre ',
    'Décembre',
  ]);
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    Get_Data_by_Month(props.query).then((res) => {
      let months = [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre ',
        'Décembre',
      ];
      console.log("==========> Chart DATA")
      console.log(res.data)
      const monthsFilter = res.data.map(element => {
        return months[element._id.month - 1]
      })
      monthsFilter.length != 1 ? setFilter(0) : setFilter(1)
      setMonths(monthsFilter);
      setyearStats(res.data);
      setOldData(res.data);
      setLoading(false)
    });
  }, [props.query]);

  const handleZoomOut = () => {
    setFilter(0);
    setyearStats(OldData);
  }


  var optionsBar = {
    options: {
      chart: {
        type: props.type,
        height: 250,
        width: '100%',
        stacked: false,
        foreColor: '#999',
        events: {
          click: function (event, chartContext, config) {
            // console.log(months[config.dataPointIndex])
            if (yearStats.length > 1 && config.dataPointIndex != -1) {
              setFilter(config.dataPointIndex + 1);
              let selected = [OldData[config.dataPointIndex]]
              console.log(selected)
              setyearStats(selected);
            }
            // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts.
          }
        },
      },
      stroke: {
        curve: 'smooth',
      },
      plotOptions: {
        bar: {
          columnWidth: '75%',
          endingShape: 'rounded',
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: ['#3a57e8', '#4bc7d2'],
      dataLabels: {
        enabled: false,
      },

      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          show: false,
        },
        categories:
          filter == 0
            ? months
            : yearStats.length > 0 &&
            yearStats[0].totalWeightDay.map((item, index) => {
              let day_of_year = (item.week - 1) * 7 + item.dayOfWeek + 2
              let date = new Date(item.year, 0, day_of_year);
              return date.getUTCDate();
            }),
        labels: {
          show: true,
          style: {
            fontSize: '14px',
          },
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
      legend: {
        show: true,
        floating: true,
        position: 'top',
        horizontalAlign: 'right',
        offsetY: -36,
      },
      title: {
        text: props.title,
        align: 'left',
      },
      subtitle: {
        text: filter == 0 ? props.currentYear : months[filter - 1] + " " + props.currentYear,
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
    },
    series:
      props.series.length == 2
        ? [
          {
            name: props.names[0],
            data:
              yearStats.length > 0 && filter == 0
                ? yearStats.map((item) => {
                  return parseFloat(item[`${props.series[0]}`]).toFixed(2);
                })
                : yearStats.length > 0 &&
                yearStats[0].totalWeightDay.map((item) => {
                  return parseFloat(item[`${props.series[0]}`]).toFixed(2);
                }),
          },
          {
            name: props.names[1],
            data:
              yearStats.length > 0 && filter == 0
                ? yearStats.map((item) => {
                  return parseFloat(item[`${props.series[1]}`]).toFixed(2);
                })
                : yearStats.length > 0 &&
                yearStats[0].totalWeightDay.map((item) => {
                  return parseFloat(item[`${props.series[1]}`]).toFixed(2);
                }),
          },
        ]
        : [
          {
            name: props.names[0],
            data:
              yearStats.length > 0 && filter == 0
                ? yearStats.map((item) => {
                  return parseFloat(item[`${props.series[0]}`]).toFixed(2);
                })
                : yearStats.length > 0 &&
                yearStats[0].totalWeightDay.map((item) => {
                  return parseFloat(item[`${props.series[0]}`]).toFixed(2);
                }),
          },
        ],
  };

  return (
    <>
      {
        Loading ?
          (<Box sx={{ display: "flex", width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }} >
            <CircularProgress />
          </Box >) :
          (<div class='box shadow'>
            <Button variant="text" onClick={handleZoomOut}> <ZoomOutMapIcon />&nbsp;Zoom Out</Button>
            <Chart
              options={optionsBar.options}
              series={optionsBar.series}
              type={props.type}
              width='100%'
              height='250'
            />
          </div>)
      }
    </>
  );
};

export default Apexchart;
