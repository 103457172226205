/** @format */

import axios from 'axios';
import { BASE_URI_NODE } from '../config/config';
let token = JSON.parse(localStorage.getItem('token'));
const config = {
  headers: {
    Authorization: `Bearer ${token?.access_token}`,
  },
};

///New API V1 LOCAL


export const Filter_Data_Booking = (query) => {
  return axios.post(`${BASE_URI_NODE}/Booking/filtrer_data`, query);
};

export const GET_BOOKING_BY_RENTAL = (id, page = 1) => {
  return axios.get(
    `${BASE_URI_NODE}/Booking/getAllBookingsbyRental/${id}/${page}`,
    config
  );
};

export const GENERATE_FACTURE = (query) => {
  return axios.post(`${BASE_URI_NODE}/Booking/generate_invoice`, query);
};
export const Get_Bookings_Stats = () => {
  return axios.get(`${BASE_URI_NODE}/Booking/generate_stats`);
}; ///source_stats
export const Get_Bookings_Sources_Stats = () => {
  return axios.get(`${BASE_URI_NODE}/Booking/source_stats`);
};

export const Filter_Bookings_List = (query) => {
  return axios.post(`${BASE_URI_NODE}/Booking/filter_stats`, query);
};

export const Get_Main_Global_Stats = (query) => {
  return axios.post(`${BASE_URI_NODE}/Booking/main_stats`, query);
};

export const Get_Data_by_Month = (query) => {
  return axios.post(
    `${BASE_URI_NODE}/Booking/get_data_by_month_filtred`,
    query
  );
};

export const Get_Data_Taux_Occupation = (query) => {
  return axios.post(`${BASE_URI_NODE}/Booking/calculer_taux_occupation`, query);
};

export const Get_All_Adresses = (query) => {
  return axios.get(`${BASE_URI_NODE}/Booking/get_all_adresses`, query);
};

export const Get_All_Years = (query) => {
  return axios.get(`${BASE_URI_NODE}/Booking/get_all_years`, query);
};

export const Get_Bedroom_Count = (query) => {
  return axios.get(`${BASE_URI_NODE}/Booking/get_bedroom_count`, query);
};

export const Get_Sleeping_Bed_Count = (query) => {
  return axios.get(`${BASE_URI_NODE}/Booking/get_sleeping_bed_count`, query);
};

export const Get_Occupation_Rate = (query) => {
  return axios.post(`${BASE_URI_NODE}/Booking/get_occupation_rate`, query);
};


