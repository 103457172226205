/** @format */

import axios from 'axios';
import {
  CLIENT_ID,
  BASE_URI_NODE,
  CLIENT_SECRET,
  REDIRECT_URI,
} from '../config/config';

let auth_credentials = {
  client_id: CLIENT_ID,
  client_secret: CLIENT_SECRET,
  grant_type: 'authorization_code',
  redirect_uri: REDIRECT_URI,
};
export const Get_Auth_Token = (code) => {
  auth_credentials.code = code;
  return axios.post(`${BASE_URI_NODE}/auth/GET_Token_Auth`, auth_credentials);
};
