/** @format */

//router
import IndexRouters from './router/index';
import axios from 'axios';
//scss
import './assets/scss/hope-ui.scss';
import './assets/scss/dark.scss';
import './assets/scss/rtl.scss';
import './assets/scss/custom.scss';
import './assets/scss/customizer.scss';

function App() {
  console.log(process.env.REACT_APP_ENVIRONNEMENT, 'env');
  return (
    <div className='App'>
      <IndexRouters />
    </div>
  );
}

export default App;
