/** @format */

import axios from 'axios';
import { BASE_URI_NODE } from '../config/config';

//Using Our Own Api

export const Get_all_Filter_Rentals = () => {
  return axios.get(`${BASE_URI_NODE}/Rentals/get_rentals_filter`);
};

export const Get_Rentals_By_Owners = () => {
  return axios.get(`${BASE_URI_NODE}/Rentals/get_rentals_group_by_owner`);
};
