/** @format */

import Grid from '@mui/material/Grid';
import React, { useEffect, useState, CSSProperties } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { Row, Col, Image } from 'react-bootstrap';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import PdfDocument from '../DetailsRental/Invoice/Invoice';
import InputLabel from '@mui/material/InputLabel';
//api
import { Button } from 'react-bootstrap';
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";

import {
  GET_BOOKING_BY_RENTAL,
  GENERATE_FACTURE,
  Get_All_Years
} from '../../api/action_booking';

//progressbar
import Progress from '../../components/progress.js';
import Pagination from '@mui/material/Pagination';

// img

import shap5 from '../../assets/images/shapes/05.png';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Calenders from '../../views/uikit/calender';
import FilterListIcon from '@mui/icons-material/FilterList';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useRef } from 'react';

const DetailsRentals = (props) => {
  //history

  const owner = (props.location && props.location.state) || {};
  const [SelectedRental, SetSelectedRental] = useState(owner.rentals[0]);
  const [Page, SetPage] = useState(1);
  const [Max_Page, Set_Max_Page] = useState(1);
  const [Bookings, setBookings] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [InvoiceData, setInvoiceData] = useState([]);
  ///Filter States

  const [Sources, SetSources] = useState([
    'Airbnb',
    'Booking.com',
    'homesuitehome.com',
    'Réservation Direct',
  ]);
  // const [StartDate, setStartDate] = useState('');
  // const [EndDate, setEndDate] = useState('');
  const [mindDate, setMinDate] = useState('');
  const [value, setValue] = React.useState([]);


  const [months, setMonths] = useState([
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre ',
    'Décembre',
  ]);
  const [monthFilter, setMonthFilter] = useState("")
  const [years, setYears] = useState([]);
  const [yearFilter, setYearFilter] = useState("")
  const [billNumber, setBillNumber] = useState(0)


  // const monthMapper = (month_in_english) => {
  //   const map = {
  //     January: 'Janvier',
  //     February: 'Février',
  //     March: 'Mars',
  //     April: 'Avril',
  //     May: 'Mai',
  //     June: 'Juin',
  //     July: 'Juillet',
  //     August: 'Août',
  //     September: 'Septembre',
  //     October: 'Octobre',
  //     November: 'Novembre',
  //     December: 'Décembre',
  //   }
  //   return map[month_in_english]
  // }

  // const reverseMapper = (month_in_french) => {
  //   const map = {
  //     Janvier: 'January',
  //     Février: 'February',
  //     Mars: 'March',
  //     Avril: 'April',
  //     Mai: 'May',
  //     Juin: 'June',
  //     Juillet: 'July',
  //     Août: 'August',
  //     Septembre: 'September',
  //     Octobre: 'October',
  //     Novembre: 'November',
  //     Décembre: 'December',
  //   }
  //   return map[month_in_french]
  // }

  useEffect(() => {
    Get_All_Years(null)
      .then((result) => {
        console.log(result.data)
        setYears(result.data)
      })
  }, [])

  const handleYearChange = (event) => {
    setYearFilter(event.target.value);
  }

  const handleMonthChange = (event) => {
    setMonthFilter(event.target.value);
  }

  const handleNumberChange = (event) => {
    setBillNumber(event.target.value);
    console.log(event.target.value)
  }
  // const isNaN = (number) => {
  //   return number !== number;
  // };

  const Calculate_paiment = (booking) => {
    let total_amount = parseFloat(booking.final_price_x).toFixed(2);
    let paid_amount = parseFloat(booking.paid_amount).toFixed(2);
    let left_amount = parseFloat(booking.payment_left_to_collect).toFixed(2);
    console.log(total_amount, paid_amount);
    console.log(100 / (paid_amount / total_amount));
    return 100 / (paid_amount / total_amount);
  };

  const handleChange = (event) => {
    SetSelectedRental(event.target.value);
  };
  const handleChangePage = (event, value) => {
    SetPage(value);
  };

  useEffect(() => {
    console.log(SelectedRental);
    GET_BOOKING_BY_RENTAL(SelectedRental.id, Page).then((res) => {
      console.log("================> DATA BOOKING")
      console.log(res.data.Bookings);
      Set_Max_Page(res.data.Bookings.totalPages);
      setBookings(res.data.Bookings.docs);
    });
  }, [SelectedRental]);

  useEffect(() => {
    GET_BOOKING_BY_RENTAL(SelectedRental.id, Page).then((res) => {
      console.log("================> ")
      console.log(res.data.Bookings.docs);
      setBookings(res.data.Bookings.docs);
    });
  }, [Page]);

  //MiniComponents
  const SelectHandler = () => {
    return (
      <Box sx={{ minWidth: 50, color: 'black' }}>
        <FormControl fullWidth>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            placeholder='propriété'
            value={SelectedRental}
            onChange={handleChange}>
            {owner.rentals.map((rent) => (
              <MenuItem value={rent}>{rent.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  };
  const HeaderCard = () => {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          height: '25vh',
          padding: '20px',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <div style={{ display: 'flex' }}>
          <div>
            <img
              style={{ width: '300px', height: '100%' }}
              src={
                SelectedRental.photos[0]
              }
              alt='rental_img'
            />
          </div>
          <div style={{ padding: '12px', lineHeight: '2' }}>
            <h2>{SelectedRental.name}</h2>
            <span>
              {SelectedRental.address1},{SelectedRental.zip},
              {SelectedRental.city}
            </span>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '',
                fontWeight: 'bold',
                width: '100%',
              }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginRight: '10px',
                  flexDirection: 'column',
                }}>
                <span>Salles de bain</span>
                <div>
                  <span>{SelectedRental.bathrooms_count}</span>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginRight: '10px',
                  flexDirection: 'column',
                }}>
                <span>Chambres</span>
                <div>
                  <span>{SelectedRental.bedrooms_count}</span>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginRight: '10px',
                  flexDirection: 'column',
                }}>
                <span>Capacité</span>
                <div>
                  <span>{SelectedRental.sleeps_max}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: '30%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}>
          <div>{SelectHandler()}</div>
          <div> {Filter_bar()}</div>
        </div>
      </div>
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const resetFilter = () => {
    // setStartDate('');
    // setEndDate('');
    setValue([]);
    SetSources([
      'Airbnb',
      'Booking.com',
      'homesuitehome.com',
      'Réservation Direct',
    ]);
    handleClose();
  };

  const handleFilter = () => {
    // console.log('handleFilter', Sources, value, StartDate === '', EndDate);
    let year = years[yearFilter - 1]
    const StartDate = new Date(year, monthFilter - 1, 1)
    const EndDate = new Date(year, monthFilter, 1)

    console.log([StartDate, EndDate])
    let query = {};
    if (Sources.length !== 4 && Sources.length !== 0) {
      query.source = { $in: Sources };
    }
    query.start_at = { $gte: StartDate.toISOString(), $lte: EndDate.toISOString() };

    if (SelectedRental.id !== null) {
      query.rental_id = parseInt(SelectedRental.id);
    }

    console.log(query)
    GENERATE_FACTURE(query).then((res) => {
      console.log(res.data);
      setInvoiceData(res.data);
    });
    // setStartDate('');
    // setEndDate('');
    setValue([]);
    SetSources([
      'Airbnb',
      'Booking.com',
      'homesuitehome.com',
      'Réservation Direct',
    ]);
    //handleClose();
  };

  const handle_Sources = (value) => {
    let Arr = Sources;
    if (Sources.indexOf(value) !== -1) {
      Arr.splice(Sources.indexOf(value), 1);
    } else {
      Arr.push(value);
    }
    SetSources([...Arr]);
  };

  const saveToPublic = async () => {
    const blob = await pdf(
      <PdfDocument
        Data={InvoiceData}
        Rental={SelectedRental}
        month={months[monthFilter - 1]}
        number={billNumber}
      >
      </PdfDocument>
    ).toBlob();
    console.log(blob);
    saveAs(blob, "Facture " + months[monthFilter - 1] + " " + years[yearFilter - 1]);
    setInvoiceData([])
  }

  // const handle_end_date = (value) => {
  //   setEndDate(value);
  // };

  // const handle_start_date = (value) => {
  //   setStartDate(value);
  // };

  const Filter_bar = () => {
    return (
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>
          <DialogTitle id='alert-dialog-title'>
            {' '}
            {' Créer une Facture'}
            <FilterListIcon />
          </DialogTitle>
          <DialogContent style={{ height: '350px' }}>
            <Grid item xs={12} >
              <DialogContentText id='alert-dialog-description' style={{ marginBottom: '10px' }}>
                Durée de facturation{' '}
              </DialogContentText>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id='demo-simple-select-label'>Année</InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={yearFilter}
                      label='Année'
                      onChange={handleYearChange}>
                      {years.map((element, index) => (
                        <MenuItem value={index + 1}>{element}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id='demo-simple-select-label'>Mois</InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={monthFilter}
                      label='Mois'
                      onChange={handleMonthChange}>
                      {months.map((element, index) => (
                        <MenuItem value={index + 1}>{element}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <DialogContentText id='alert-dialog-description' style={{ marginBottom: '10px' }}>
              Numéro de Facture{' '}
            </DialogContentText>
            <TextField
              id="outlined-basic"
              label="Numéro de Facture"
              variant="outlined"
              onChange={handleNumberChange}
            />

            <DialogContentText id='alert-dialog-description' style={{ marginBottom: '10px' }}>
              Sources{' '}
            </DialogContentText>

            <Grid container spacing={3}>
              <Grid item xs={3}>
                <div class='form-element'>
                  <input
                    type='checkbox'
                    name='platform'
                    value='Airbnb'
                    id='airbnb'
                    checked={Sources.includes('Airbnb')}
                    onChange={(e) => {
                      handle_Sources(e.target.value);
                    }}
                  />
                  <label for='airbnb'>
                    <div class='icon'>
                      <div class='title'>Airbnb</div>
                    </div>
                  </label>
                </div>
              </Grid>
              <Grid item xs={3}>
                {' '}
                <div class='form-element'>
                  <input
                    type='checkbox'
                    name='platform'
                    value='Booking.com'
                    id='booking'
                    checked={Sources.includes('Booking.com')}
                    onChange={(e) => {
                      handle_Sources(e.target.value);
                    }}
                  />
                  <label for='booking'>
                    <div class='icon'>
                      <div class='title'>Booking.com</div>
                    </div>
                  </label>
                </div>
              </Grid>
              <Grid item xs={3}>
                {' '}
                <div class='form-element'>
                  <input
                    type='checkbox'
                    name='platform'
                    value='homesuitehome.com'
                    id='HomeSuiteHome'
                    checked={Sources.includes('homesuitehome.com')}
                    onChange={(e) => {
                      handle_Sources(e.target.value);
                    }}
                  />
                  <label for='HomeSuiteHome'>
                    <div class='icon'>
                      <div class='title'>Home Suite Home</div>
                    </div>
                  </label>
                </div>
              </Grid>
              <Grid item xs={3}>
                {' '}
                <div class='form-element'>
                  <input
                    type='checkbox'
                    name='platform'
                    value={'Réservation Direct'}
                    checked={Sources.includes('Réservation Direct')}
                    onChange={(e) => {
                      handle_Sources(e.target.value);
                    }}
                    id='RéservationDirect'
                  />
                  <label for='RéservationDirect'>
                    <div class='icon'>
                      <div class='title'>Réservation Direct</div>
                    </div>
                  </label>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              style={{
                backgroundColor: '#e9ecef',
                color: '#8a92a6',
                border: 'none',
              }}
              onClick={resetFilter}>
              Annuler
            </Button>

            <Button
              style={{ backgroundColor: '#4bc7d2', border: 'none' }}
              onClick={handleFilter}
              autoFocus>
              Créer
            </Button>
            {/* <PDFDownloadLink
              document={
                <PdfDocument
                  Data={InvoiceData}
                  Rental={SelectedRental}
                  month={months[monthFilter - 1]}
                  number={billNumber}
                >
                </PdfDocument> 
              fileName='Facture'> */}
            {InvoiceData.length > 0 && (
              <Button
                color='green'
                style={{
                  marginLeft: '10px',
                  backgroundColor: '#4bc7d2',
                  border: 'none',
                }}
                onClick={saveToPublic}
              >
                Télecharger la facture
              </Button>
            )}
            {/* </PDFDownloadLink> */}
          </DialogActions>
        </Dialog>
        <Button
          style={{
            width: '180px',
            marginTop: '10px',
            height: '46px',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#4bc7d2',
            border: 'none',
          }}
          onClick={handleClickOpen}
          variant='primary  '>
          <span className='btn-inner'>
            {/* <FilterListIcon /> */}
            Créer une facture
          </span>
        </Button>
      </Row>
    );
  };

  const BookingTable = () => {
    return (
      <>
        <Row>
          <Col sm='12'>
            <Card
              style={{
                overflowY: 'scroll',
                height: '50vh',
                overflowX: 'hidden',
              }}>
              <Card.Header className='d-flex justify-content-between'>
                <div className='header-title'>
                  <h4 className='card-title'>Bookings</h4>
                </div>
              </Card.Header>
              <Card.Body className='p-0'>
                <div className='table-responsive mt-4'>
                  <table
                    id='basic-table'
                    className='table table-striped mb-0'
                    role='grid'>
                    <thead>
                      <tr>
                        <th>DATE DÉBUT</th>
                        <th>DATE FIN </th>
                        <th>STATUS</th>
                        <th>NOMBRE ADULTES</th>
                        <th>PAIEMENTS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Bookings.map((Booking) => {
                        return (
                          <tr>
                            <td>
                              <div className='d-flex align-items-center'>
                                <Image
                                  className='rounded img-fluid avatar-40 me-3 bg-soft-primary'
                                  src={shap5}
                                  alt='profile'
                                />
                                <h6>{Booking.start_at.slice(0, 10)}</h6>
                              </div>
                            </td>
                            <td>
                              <div className='iq-media-group iq-media-group-1'>
                                <h6>{Booking.end_at.slice(0, 10)}</h6>
                              </div>
                            </td>
                            <td>{Booking.status}</td>
                            <td>
                              <div className='text-info'>{Booking.adults}</div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center mb-2'>
                                <h6>
                                  {parseFloat(Booking.final_price_x).toFixed(
                                    2
                                  ) + ' €'}
                                </h6>
                              </div>
                              <Progress
                                softcolors='info'
                                color='info'
                                className='shadow-none w-100'
                                value={Calculate_paiment(Booking)}
                                minvalue={0}
                                maxvalue={100}
                                style={{ height: '6px' }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col
            sm='12'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Pagination
              count={Max_Page}
              page={Page}
              onChange={handleChangePage}
              variant='outlined'
              shape='rounded'
            />
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Grid container sx={{ my: 5 }} spacing={2}>
      <Grid item md={12} sx={{ my: 5 }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '20px',
          }}>
          <Link to={'/dashboard/app/owners'}>
            <ArrowBackIcon
              fontSize={'large'}
              style={{
                color: 'black',
                marginRight: '15px',
                fontSize: '50px',
                cursor: 'pointer',
              }}
            />
          </Link>
        </div>{' '}
        <Container maxWidth='xl'>
          <div>{HeaderCard()}</div>
          <div style={{ marginTop: '20px' }}>{BookingTable()}</div>
        </Container>
      </Grid>
    </Grid>
  );
};

export default DetailsRentals;
