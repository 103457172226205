/** @format */

import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Row, Col, Card } from 'react-bootstrap';
import { Get_Rentals_By_Owners } from '../../api/action_rentals';
import { useHistory } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { Form } from 'react-bootstrap';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const Rentals = () => {
  //Pagination
  const [Page, SetPage] = useState(1);
  const [Max_Page, Set_Max_Page] = useState(1);
  //History
  let history = useHistory();
  //States
  const [OwnerList, SetOwnerList] = useState([]);
  const [paginatedOwnerList, SetPaginatedOwnerList] = useState([]);
  const [currentOwnerList, setCurrentOwnerList] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [FiltredList, setFiltredList] = useState([]);
  const [Name, setName] = useState();

  //LifeCycle
  useEffect(() => {
    Get_Rentals_By_Owners().then((res) => {
      console.log(res.data)
      SetOwnerList(res.data.owners);
      setFiltredList(
        res.data.owners.sort((a, b) =>
          a.proprietary > b.proprietary ? 1 : b.proprietary > a.proprietary ? -1 : 0
        )
      );
      setisLoading(false);
    });
  }, []);

  useEffect(() => {
    if (OwnerList.length != 0) {
      var pageCount = OwnerList.length % 28 != 0 ? Math.trunc(OwnerList.length / 28) + 1 : OwnerList.length / 28
      Set_Max_Page(pageCount);
      var paginated = {}
      var tempArray = []
      var currentPage = 0;
      for (var i = 0; i < OwnerList.length; i++) {
        if ((i % 28 != 0 && (i != OwnerList.length - 1)) || i == 0) {
          tempArray.push(OwnerList[i])
        }
        else {
          currentPage++;
          paginated[currentPage] = [...tempArray]
          tempArray = []
        }
      }
      SetPaginatedOwnerList(paginated)
      setCurrentOwnerList(paginated[1])
    }
  }, [OwnerList])

  useEffect(() => {
    console.log(OwnerList);
  }, [OwnerList]);

  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== '') {
      const results = OwnerList.filter((user) =>
        user.rentals.some((rental) => {
          if (rental.name.toLowerCase().includes(keyword.toLowerCase())) {
            return user;
          }
          // Use the toLowerCase() method to make it case-insensitive
        })
      );
      setCurrentOwnerList(
        results.sort((a, b) => (a.proprietary > b.proprietary ? 1 : b.proprietary > a.proprietary ? -1 : 0))
      );
    } else {
      setCurrentOwnerList(
        paginatedOwnerList[1].sort((a, b) =>
          a.proprietary > b.proprietary ? 1 : b.proprietary > a.proprietary ? -1 : 0
        )
      );
      // If the text field is empty, show all users
    }
    setName(keyword);
  };

  const handleChangePage = (event, value) => {
    SetPage(value);
    setCurrentOwnerList(paginatedOwnerList[value]);
  };

  //template
  return (
    <Grid container sx={{ my: 5 }} spacing={2}>
      <Grid item md={12}>
        <div className='mb-3'>
          <Form.Control
            type='text'
            className=''
            id='exampleInputEmail1'
            autoComplete='username email'
            aria-describedby='emailHelp'
            placeholder='Rechercher'
            value={Name}
            onChange={filter}
          />
        </div>
        <Col
          sm='12'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Pagination
            count={Max_Page}
            page={Page}
            onChange={handleChangePage}
            variant='outlined'
            shape='rounded'
          />
        </Col>
      </Grid>
      {isLoading ? (
        <Box sx={{ display: "flex", width: '100%', height: '80vh', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        currentOwnerList.map((Owner) => (
          <Grid item md={3}>
            <Card
              onClick={() => {
                history.push({
                  pathname: '/dashboard/app/rentals',
                  state: Owner,
                });
              }}
              className='mb-0'
              style={{
                cursor: 'pointer',
              }}>
              <Row className='g-0'>
                <Col
                  md='4'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <img
                    style={{ width: '80%' }}
                    src='https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-PNG-Picture.png'
                    alt=''
                  />
                </Col>
                <Col md='8'>
                  <Card.Body>
                    <h5 className='card-title'>{Owner.proprietary}</h5>
                    <p className='card-text'>
                      <small className='text-muted'>
                        {Owner.rentals.length} propriété
                      </small>
                    </p>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Grid>
        ))
      )}
      <hr />
    </Grid>
  );
};

export default Rentals;
