/** @format */

import React from 'react';

//router
import { Switch, Route } from 'react-router';
//layoutpages
import Default from '../layouts/dashboard/default';
import Auth from '../Pages/auth/Auth';
const IndexRouters = () => {
  return (
    <>
      <Switch>
        <Route exact path='/' component={Auth}></Route>
        <Route path='/dashboard' component={Default}></Route>
      </Switch>
    </>
  );
};

export default IndexRouters;
