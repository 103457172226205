/** @format */

import React, { useEffect } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import Card from '../../components/Card';
import { useLocation } from 'react-router-dom';
//config
import { Authorisation_URI } from '../../config/config';
//api
import { Get_Auth_Token } from '../../api/action_auth';
// img
import auth1 from '../../assets/images/auth/01.png';

const Auth = () => {
  let history = useHistory();
  const search = useLocation().search;
  const code = new URLSearchParams(search).get('code');

  useEffect(() => {
    code &&
      Get_Auth_Token(code)
        .then((res) => {
          localStorage.setItem('user', JSON.stringify(res.data.account));
          localStorage.setItem('token', JSON.stringify(res.data.token));
          localStorage.setItem(
            'refresh_token',
            JSON.stringify(res.data.refresh_token)
          );
          history.push('/dashboard');
          window.location.reload();
        })
        .catch((err) => console.error(err));
  });
  return (
    <>
      <section className='login-content'>
        <Row className='m-0 align-items-center bg-white vh-100'>
          <Col md='6'>
            <Row className='justify-content-center'>
              <Col md='10'>
                <Card className='card-transparent shadow-none d-flex justify-content-center mb-0 auth-card'>
                  <Card.Body>
                    <Link
                      to='/dashboard'
                      className='navbar-brand d-flex align-items-center mb-3'>
                      <h4 className='logo-title ms-3'>HomeSuiteHome</h4>
                    </Link>
                    <h2 className='mb-2 text-center'>Sign In</h2>
                    <p className='text-center'>
                      {' '}
                      <h2 className='mb-2 text-center'>Connectez-vous</h2>
                    </p>

                    <div className='d-flex justify-content-center'>
                      <a
                        href={Authorisation_URI}
                        style={{ width: '80%' }}
                        type='button'
                        variant='btn btn-primary'>
                        Connectez-vous avec bookingsync
                      </a>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <div className='sign-bg'>
              <svg
                width='280'
                height='230'
                viewBox='0 0 431 398'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <g opacity='0.05'>
                  <rect
                    x='-157.085'
                    y='193.773'
                    width='543'
                    height='77.5714'
                    rx='38.7857'
                    transform='rotate(-45 -157.085 193.773)'
                    fill='#3B8AFF'
                  />
                  <rect
                    x='7.46875'
                    y='358.327'
                    width='543'
                    height='77.5714'
                    rx='38.7857'
                    transform='rotate(-45 7.46875 358.327)'
                    fill='#3B8AFF'
                  />
                  <rect
                    x='61.9355'
                    y='138.545'
                    width='310.286'
                    height='77.5714'
                    rx='38.7857'
                    transform='rotate(45 61.9355 138.545)'
                    fill='#3B8AFF'
                  />
                  <rect
                    x='62.3154'
                    y='-190.173'
                    width='543'
                    height='77.5714'
                    rx='38.7857'
                    transform='rotate(45 62.3154 -190.173)'
                    fill='#3B8AFF'
                  />
                </g>
              </svg>
            </div>
          </Col>
          <Col
            md='6'
            className='d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden'>
            <Image
              src={auth1}
              className='Image-fluid gradient-main animated-scaleX'
              alt='images'
            />
          </Col>
        </Row>
      </section>
    </>
  );
};

export default Auth;
