/** @format */

import React from 'react';
import Index from '../views/dashboard/index';
import { Switch, Route } from 'react-router-dom';

//TransitionGroup
import { TransitionGroup, CSSTransition } from 'react-transition-group';
//admin
import Rentals from '../Pages/Rentals/Rentals';
import DetailsRentals from '../Pages/Rentals/DetailsRentals';
const DefaultRouter = () => {
  return (
    <TransitionGroup>
      <CSSTransition classNames='fadein' timeout={300}>
        <Switch>
          <Route path='/dashboard' exact component={Index} />
          {/* user */}
          <Route path='/dashboard/app/owners' exact component={Rentals} />
          <Route
            path='/dashboard/app/rentals'
            exact
            component={DetailsRentals}
          />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default DefaultRouter;
